import React, { useEffect, useState } from 'react';
import img from './assets/BANNER.jpg';
import {db} from '../Config/Config';
import {  collection, getDocs } from 'firebase/firestore';

export default function College() {
  const [collegeList, setcollegeList] = useState([]);
  const fetchCollege = async () => {
    const ref = collection(db, "colleges");
    const querySnapshot = await getDocs(ref);
    const data = [];
    querySnapshot.forEach((doc) => {
      data.push({...doc.data(), id: doc.id});
      console.log(doc.data())
    });
    setcollegeList(data);
  }
  useEffect(()=>{
    fetchCollege();
  },[])


  return (
    <div>
      <div
        className="w-full mx-auto px-9 py-8 bg-cover bg-center relative"
        style={{ backgroundImage: `url(${img})` }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>{" "}
        {/* Overlay */}
        <div className="relative flex items-center text-white">
          <div className="flex font-semibold text-lg items-center text-gray-900  transition-colors duration-300">
            <svg
              className="w-4 h-4 mr-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
            </svg>
            Home
          </div>
          <span className="mx-2">/</span>
          <h2 className="text-[#f2f2f2] font-bold text-xl">Colleges</h2>
        </div>
      </div>

      {/* Content Section */}
      <div class="container mx-auto p-6 bg-white text-gray-900">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          {collegeList &&
            collegeList.map((doc) => {
              return (
                <div class="bg-white p-6 rounded-lg border border-gray-200">
                  <div class="bg-gradient-to-r from-[#881337] to-[#5a2222] text-white py-3 px-4 rounded-t-lg">
                    <h2 class="text-xl font-semibold">{doc.collegename}</h2>
                  </div>
                  <div class="p-6 bg-white rounded-b-lg">
                    {doc.manager ? (
                      <p class="text-[#373737] mb-2">
                        <strong>Manager:</strong>
                        {doc.manager}
                      </p>
                    ) : null}
                    {doc.principal ? (
                      <p class="text-[#373737] mb-2">
                        <strong>Principal:</strong> {doc.principal}
                      </p>
                    ) : null}
                    {doc.administrativeofficer ? (
                      <p class="text-[#373737] mb-2">
                        <strong>Administrative Officer:</strong>{" "}
                        {doc.administrativeofficer}
                      </p>
                    ) : null}
                    {doc.mobile ? (
                      <p class="text-[#373737] mb-2">
                        <strong>Phone:</strong> {doc.mobile}
                      </p>
                    ) : null}
                    {doc.address ? (
                      <p class="text-[#373737] mb-2">
                        <strong>Address:</strong> {doc.address}
                      </p>
                    ) : null}
                    {doc.website ? (
                      <p class="text-[#373737] mb-2">
                        <strong>Website:</strong>{" "}
                        <a
                          href="http://www.mcccalicut.org"
                          class="text-blue-600 underline"
                        >
                          {doc.website}
                        </a>
                      </p>
                    ) : null}
                    {doc.email ? (
                      <p class="text-[#373737] mb-2">
                        <strong>Email:</strong>{" "}
                        <a
                          href="mailto:admin@mcccalicut.org"
                          class="text-blue-600 underline"
                        >
                          {doc.email}
                        </a>
                      </p>
                    ) : null}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
