/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import img from "./assets/BANNER.jpg";
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from '../Config/Config';
import { useNavigate } from 'react-router-dom';
const LightsOfOurDiocese = () => {
  const [data, setdata] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {

    const datas = [];
    const fetchData = async () => {
      const q = query(collection(db, "diocese-lights"), orderBy("order", "asc"));
      const data = await getDocs(q);
      data.forEach((doc) => {
        datas.push({ ...doc.data(), id: doc.id });
      })
    setdata(datas);
    };
              

    fetchData();
  }, []);

  // Sample SVG icon (a star icon for a stylish look)
  const StarIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className="w-4 h-4 text-#881337"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
    </svg>
  );

  return (
    <div>
      <div
        className="w-full mx-auto px-9 py-8 bg-cover bg-center"
        style={{ backgroundImage: `url(${img})` }} // Set the local image as the background
      >
        <div className="flex items-center text-black">
          {/* Home SVG Icon */}
          <a
            href="#"
            className="flex font-semibold text-lg items-center text-gray-900  transition-colors duration-300"
          >
            <svg
              className="w-4 h-4 mr-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
            </svg>
            Home
          </a>
          <span className="mx-2">/</span>
          <h2 className="text-[#881337]">Lights of our Diocese</h2>
        </div>
      </div>
 
    <section className="bg-gradient-to-br from-white to-gray-200 py-14">
      <div className="container mx-auto max-w-6xl px-6">
        <h2 className="text-4xl font-extrabold  text-[#881337]  mb-12">Lights of our Diocese</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
          {data.map((category, index) => (
            <div style={{cursor: "pointer"}} onClick={() => {
              navigate("/AboutLights",{
                state:{
                  id: category.id,
                  name: category.name
                }
              });
            }}
              className="flex items-center p-8 bg-white rounded-3xl shadow-2xl transition-transform duration-500 "
            >
              <div className="mr-6">
                <StarIcon />
              </div>
              <h3 className="text-xl font-semibold text-gray-800">{category.name}</h3>
            </div>
          ))}
        </div>
      </div>
    </section>
    </div>
  );
};

export default LightsOfOurDiocese;
